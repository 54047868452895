import React, { useState } from "react";
import { connect } from 'react-redux';
import Counter from "./Counter";
import ImageUtil from '../utils/ImageUtil';
import { to2Decimal, formatINR } from '../utils/CommonUtil';
import { selectProduct, getItemsByVariant, setVariantModal } from '../actions/ProductsAction';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { APIAlert } from '../utils/AlertUtil';
import { isProd } from '../utils/Env';
//import GenericModal from "./Generic Modal";

import { Card, ListGroup } from 'react-bootstrap';
import ProductLoading from './ProductLoading';

const Product = (props) => {
  const [loading, setLoading] = useState(false);
  let { product } = props;
  const navigate = useNavigate();
  const cart = props.cartList;
  let variant = product.variant;
  let index = cart.findIndex(x => x.id === product.id);
  if (index !== -1) {
    product = { ...cart[index], variant };
  }
  const sizeHandler = () => {
    props.getItemsByVariant({ companyid: props.companyid, variant: product.variant });
    props.setVariantModal(true);
  }
  const handleImgClick = () => {

    //.. get variant details
    props.getItemsByVariant({ companyid: props.companyid, variant: product.variant });
    props.selectProduct(product);
    navigate(`/products/${product.id}`)
  }

  const Overlay_name = (param) => {
    return (
      <OverlayTrigger placement='bottom' overlay={<Tooltip className="tooltip"> {param}</Tooltip>}>
        <h4 className="product-name product-name-home elips-twoline mb-2">{param.substring(0, 34).concat("...")}</h4>
      </OverlayTrigger>
    );
  }
  const handleImageLoaded = () => {
    setLoading(true);
  }

  /* const imageHandler = () => {
    console.log(product.images[0].name);
   let img = ((product.images[0].name === null) || (product.images[0].name === 'undefined')) ? ImageUtil.getErrorImage() : ImageUtil.getImage(product.images[0].name);
   let errMsg = 'Missing->'+product.images[0].name;
     //APIAlert().error(errMsg);
     return img
   } */

  if (!isProd() && !product.images.length) {
    let errMsg = 'IMG name in db null for->' + product.name;
    APIAlert(errMsg).error();
  }

  return (

    <Col md={3} xs={6} className="pr-1 pl-1">

      <Container className={loading ? "product-box show" : "product-box hide"}>
        <Row>
          <Col className="p-0">
            <div className="position-relative">
              <span className="badge badge-pill badge-info position-absolute z-index1 percent-offer rounded-0 font-weight-normal">{product.promo_percentage ? `${product.promo_percentage} Off` : ""}</span>
              <span className="badge badge-info float-right position-absolute z-index1 mt-1 label-offer">{product.promo_name || ""}</span></div>
          </Col>
        </Row>

        <Row>
          <Col className="p-0">
            {product.brandicon ?
              <div className="brand-iconmaindiv">
                <img className="brand-img" alt={product.brandicon} src={ImageUtil.getImage(product.brandicon)} />
              </div>
              : null}

            <img className="product-img"
              src={(product.images[0]) ? ImageUtil.getImage(product.images[0].name) : ImageUtil.getErrorImage()}
              alt={product.name}
              onClick={handleImgClick}
              onError={e => e.target.src = ImageUtil.getErrorImage()}
              onLoad={handleImageLoaded}
            />
          </Col>
        </Row>

        <Row>
          <Col className="p-0">
            {(product.name.length > 37) 
                ? Overlay_name(product.name) 
                : (<h4 className="product-name product-name-home elips-twoline mb-2">{product.name}</h4>)}
            {/* Commented as description not required: Ashim=> <p className="product-des elips-threeline mb-2"></p> */}
          </Col>
        </Row>

        <Row>
          <Col className="p-0">
            <button className="btn btn-outline-success theme-outline-button mob-font-11px btn-sm" onClick={sizeHandler}>
              {product.size}&nbsp;&nbsp; {product.variant.length > 1 ? <i className="fa fa-chevron-down font-12" aria-hidden="true"></i> : ""}
            </button>
          </Col>
        </Row>

        <Row className="position-absolute  w-100 l-0 b-0 pos-rel-mobile text-center">
          <Col className="pl-2 pr-1 rate-sizecontrol" md={6} xs={12}>
            <p className="product-price">
              <span className="text-danger">
                <s>{product.promo_price ? formatINR(to2Decimal(product.price_regular)) : ""}</s>
              </span>&nbsp;&nbsp;<span>{formatINR(to2Decimal(product.promo_price ? product.promo_price : product.price_regular))}</span> </p>
          </Col>
          <Col className="pl-3 pr-3 tab-width-cont" md={6} xs={12}>
            <div className="product-action pos-rel-mobile pos-sta-tab">
              {<Counter product={product} />}
            </div>
          </Col>
        </Row>

      </Container>
      
      <Card className={loading ? "product-box hide" : "product-box show"} >
        <ProductLoading />
      </Card>
     
    </Col>
  );
}

const mapStateToProps = state => ({
  productsList: state.ProductsData.productsList,
  cartList: state.CartData.cartList,
  companyid: state.HomeData.companyid
})


export default connect(mapStateToProps, { selectProduct, getItemsByVariant, setVariantModal })(Product);