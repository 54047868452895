import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { setModal } from '../../actions/CheckoutAction';
import { clearCart } from '../../actions/CartAction';
import { cleanDiscount, clearOrder } from '../../actions/CheckoutAction';
import { clear_userDetails } from '../../actions/UserAction';
import {useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';

const GenericModal = (props) => {
    const navigate = useNavigate();
    const handleClose = () => {
        props.clearCart();
        props.cleanDiscount();
        props.clearOrder();
        //props.clear_userDetails();
        props.setModal(false);
        navigate('/products');
    }

    return (
        <React.Fragment>
            <Modal show={props.confirmationModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Thank You</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html:props.order.orderStatus}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    confirmationModal:state.CheckoutData.confirmationModal,
    order:state.CheckoutData.order
})
export default connect(mapStateToProps,{ setModal,
                                         clearCart,
                                         clearOrder,
                                         cleanDiscount,
                                         clear_userDetails }) (GenericModal);