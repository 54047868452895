import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

const PLACE_ORDER = 'PLACE_ORDER';
const CHECKOUT_ORDER = 'CHECKOUT_ORDER';
const SET_MODAL = 'SET_MODAL';
const SPECIAL_REQUEST = 'SPECIAL_REQUEST';
const SET_PROMO_MODAL = 'SET_PROMO_MODAL';
const VALIDATE_DISCOUNT = 'VALIDATE_DISCOUNT';
const CLEAN_DISCOUNT = 'CLEAN_DISCOUNT';
const CLEAR_ORDER = 'CLEAR_ORDER';
/**
 * Action placeOrder defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const checkOutOrder = (obj) => {
    return apiAction({
        url: API.CHECKOUT_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderCheckedOut,
        onFailure: () => console.log("Error occured in placeOrder"),
        callback: 'checkOutOrder',
        label: CHECKOUT_ORDER
    });
}


export const orderCheckedOut = param => ({
    type: CHECKOUT_ORDER,
    payload: param.data
});

/**
 * Action placeOrder defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const placeOrder = (obj) => {
    return apiAction({
        url: API.PLACE_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderPlaced,
        onFailure: () => console.log("Error occured in placeOrder"),
        label: PLACE_ORDER
    });
}

export const orderPlaced = param => ({
    type: PLACE_ORDER,
    payload: param.data
});

//...............................................................
/**
 * Action validateDiscount defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const validateDiscount = (obj) => {
    return apiAction({
        url: API.VALIDATE_DISCOUNT,
        method: "post",
        data: obj,
        onSuccess: discountValidated,
        onFailure: () => console.log("Error occured in validateDiscount"),
        label: VALIDATE_DISCOUNT
    });
}

export const discountValidated = param => ({
    type: VALIDATE_DISCOUNT,
    payload: param.data
});

//...................................................................
/**
 * Action setModal defined.
 * @constructor
 * @param
 */
export const setModal = (param) => ({
    type: SET_MODAL,
    payload: param
});
//...................................................................
/**
 * Action setPromModal defined.
 * @constructor
 * @param
 */
export const setPromModal = (param) => ({
    type: SET_PROMO_MODAL,
    payload: param
});
//...................................................................
/**
 * Action cleanDiscount defined.
 * @constructor
 * @param
 */
export const cleanDiscount = () => ({
    type: CLEAN_DISCOUNT,
    payload: null
});

/**
 * Action reset checkout.
 * @constructor
 * @param
 */
export const clearOrder = () => ({
    type: CLEAR_ORDER,
    payload: null
});



//................................................................... 
export const set_special_request = (param) => ({ type: SPECIAL_REQUEST, payload: param })
/**
 * set up with initial state.
 */
const initialState = {
    items: [],
    checkoutDetails: {},
    special_request: '',
    payment_mode: 'COD',
    delivery_slot: '',
    selected_voucher: {},
    confirmationModal: false,
    promoModal: false,
    order: {},
    discountDetails: {}
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ORDER: return initialState
        case PLACE_ORDER: return { ...state, order: action.payload, confirmationModal: true }
        case CHECKOUT_ORDER: return { ...state, checkoutDetails: action.payload }
        case SET_MODAL: return { ...state, confirmationModal: action.payload }
        case SET_PROMO_MODAL: return { ...state, promoModal: action.payload }
        case SPECIAL_REQUEST: return { ...state, special_request: action.payload }
        case CLEAN_DISCOUNT: return { ...state, discountDetails: {}, selected_voucher: {} }
        case VALIDATE_DISCOUNT: return {
            ...state,
            promoModal: false,
            discountDetails: action.payload,
            checkoutDetails: { ...state.checkoutDetails, ...action.payload.order },
            selected_voucher: action.payload.vouchers
        }
        default: return state;
    }
}

//...................................................................
//.. selectors :: TODO
export function selectCheckOutJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "delivery": {
            "pincode": state.LocationData.pin
        },
        "ordered": {
            "items": state.CartData.cartList.map(item => {
                return {
                    id: item.id,
                    variantid: item.variantid,
                    rate: item.promo_price ? item.promo_price : item.price_regular,
                    quantity: item.quantity,
                    status: "order placed"
                }
            })
        }
    }
}

export function selectValidateDiscountJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "order": {
            "date": state.CheckoutData.checkoutDetails.date,
            "totalamt": state.CartData.totalAmount,
            "totaltaxamt": state.CheckoutData.checkoutDetails.totaltaxamt,
            "deliverychargeamt": state.CheckoutData.checkoutDetails.deliverychargeamt,
            "grandtotalamt": state.CheckoutData.checkoutDetails.grandtotalamt
        }
    }
}
export function selectPlaceOrderJSON(state) {
    return {
        "companyid": state.HomeData.companyid,
        "customer": {
            "addrid": state.UserData.addrid,
            "fname": state.UserData.fname,
            "lname": state.UserData.lname,
            "phone": state.UserData.phone,
            "email": state.UserData.email,
            "address1": state.UserData.address1,
            "address2": state.UserData.address2,
            "pin": state.UserData.pin,
            "city": state.UserData.city,
            "dist": state.UserData.dist,
            "state": state.UserData.state,
            "country": state.UserData.country,
            "type": state.UserData.type,
            "spclrequest": state.CheckoutData.special_request,
            "paymentmode": state.CheckoutData.payment_mode,
            "deliveryslot": state.CheckoutData.checkoutDetails.deliveryslot
        },
        "order": {
            "orderdate": state.CheckoutData.checkoutDetails.date,
            "totalamt": state.CheckoutData.checkoutDetails.totalamt,
            "totaltaxamt": state.CheckoutData.checkoutDetails.totaltaxamt,
            "deliverychargeamt": state.CheckoutData.checkoutDetails.deliverychargeamt,
            "grandtotalamt": state.CheckoutData.checkoutDetails.grandtotalamt,
            "discount_code": state.CheckoutData.selected_voucher.code || '',
            "discount_total": state.CheckoutData.checkoutDetails.discount_total || '',
            "data": state.CheckoutData.checkoutDetails.item
        }
    }
}
//...................................................................

/**
 * Export Object (Action + Reducer)
 */
const CheckoutAction = {
    reducer: reducer,
    actions: {
        checkOutOrder,
        placeOrder,
        setModal,
        set_special_request,
        setPromModal,
        validateDiscount,
        cleanDiscount
    }
}

export default CheckoutAction

