import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Card, Form, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { set_phone, getCustomerDetails, validateOtp, setValidateOtp, sendOtp, clearOtpStatus } from '../../actions/UserAction';
import ResendOtp from './ResendOtp';
import { Global } from '../../utils/Env';

const PhoneValidate = (props) => {

    const [state, setState] = useState("phone");
    const [phonenumber, setPhonenumber] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [email, setEmail] = useState("");
    const [validOtp, setValidotp] = useState(false);
    
    const [otpError, setOtpError] = useState(false);

    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)

    //.. check for validation
    Global.callback.validateOtp_onComplete = () => {
        //.. if validated correctly
        if(props.valid_otp){
            setValidotp(true);
            props.getCustomerDetails({companyid:props.companyid, phone:props.phone, pin:props.pin});
        }else{
            //.. handle error in SMS OTP : set resend option
            setOtpError(true);
        }
    }

    //.. call to get customer on SMS validated
    Global.callback.getCustomerDetails_onComplete = () => {
        props.setPhnCard(false);
        props.setAddressCard(true);
    }
 
    useEffect(() => {
        //.. check if returning user and save SMS call: forward to address
        if(props.phone && props.valid_otp){
            console.log('returning user');
            props.setPhnCard(false);
            props.setAddressCard(true);
            props.getCustomerDetails({companyid:props.companyid, phone:props.phone, pin:props.pin});
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.phone, props.valid_otp]);

    const editPhoneNumber = () =>{
        props.setValidateOtp({valid:false});
        setState("phone");
        /* props.setPhnCard(true);
        props.setAddressCard(false); */

        //.. close all steps below
        props.disableAllState()
    }

    const handleOnChange = e => {
        setEmail(e.target.value)
    }

    const handleClick = () => {
        if (state === "phone") {
            //.. TODO
            reqOtpClicked();
            
        } else {
            verifyOtpClicked();
            setOtpError(state => false);
        }
    }

    const reqOtpClicked = () => {
        if (phonenumber.length === 10) {
            //setState("otp");
            props.set_phone(phonenumber);

            //.. commented below by ashim to bypass otp validation
            //props.sendOtp({companyid:props.companyid,phone:phonenumber})

            //.. and enable below 
            props.setValidateOtp({valid:true});
        }
    }

    const verifyOtpClicked = () => {
        if (otp.length === 4) {
            props.validateOtp({companyid:props.companyid, phone: phonenumber, otp: otp.join('')})
            setOtp(["", "", "", ""]); // clear otp fields
        }
    }

    const isPhoneValidated = () => {
        return props.valid_otp
    }

    const handlePhonenumberChange = (e) => {
        if (e.target.value.length <= 10) {
            setPhonenumber(e.target.value)
        }
    }
    const handleOtpChange = (e, i) => {
        let copyOtp = [...otp]
        if (e.target.value.length <= 1) {
            copyOtp[i] = e.target.value
            setOtp(copyOtp)
        }
        if (otp[i] === "") {
            switch (i + 1) {
                case 1:
                    {
                        ref2.current.focus();
                        break
                    }
                case 2:
                    {
                        ref3.current.focus();
                        break
                    }
                case 3:
                    {
                        ref4.current.focus();
                        break
                    }
                case 4:
                    {
                        ref5.current.focus();
                        break
                    }
                default: return null
            }
        }
    }

    const checkValidity = () => {
        if (state === "phone") {
            if (phonenumber.length === 10) {
                return false
            }
        }
        else {
            if (otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "") {
                return false
            }
        }
        return true
    }
    
    const renderPhoneField = () => {
        return (<React.Fragment>
                    <Row>
                        <Col>
                        <p className="w-100 fs-13-f-n">Enter Your Phone Number</p>
                        <input className="form-control" placeholder="Phone Number" type="number" value={phonenumber} onChange={handlePhonenumberChange} />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                        <p className="w-100 fs-13-f-n">Enter Your Email</p>
                        <input className="form-control" type="email" placeholder="Enter Email" value={email}
                                    name="email" onChange={handleOnChange} required  /> 
                        </Col>
                    </Row> */}
                </React.Fragment>)
    }

    const renderOtpField = () => {
        return (<Row>
            <p className="w-100 fs-13-f-n">Enter Your OTP</p>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref1} value={otp[0]} onChange={(e) => handleOtpChange(e, 0)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref2} value={otp[1]} onChange={(e) => handleOtpChange(e, 1)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref3} value={otp[2]} onChange={(e) => handleOtpChange(e, 2)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref4} value={otp[3]} onChange={(e) => handleOtpChange(e, 3)} />
            </Col>
            {otpError && <div className="text-danger font-12 mt-2">Error in otp... try again</div>}
            <ResendOtp />
        </Row> )
    }

    return (
        <Card className="mb-2">
            <Card.Header className="bgf2f2f2">
                {isPhoneValidated() 
                    ? <i className="fa fa-check-circle text-success f-size22" aria-hidden="true"></i> 
                    : <label className="number-before-check">1</label>
                } &nbsp; Phone Number 
                {isPhoneValidated() && 
                <span className="float-right cursor" onClick={editPhoneNumber}>Edit &nbsp;<i style={{ margin: "2px" }} className="fa fa-pencil float-right"></i></span>}
                {isPhoneValidated() && <div>{props.phone}</div>} 
            </Card.Header>
            <Card.Body style={{ display: isPhoneValidated() ? "none" : "block" }}>
                <Row>
                    <Col md="9 pl-0 pr-0-mob">
                        {(state === "phone") ? renderPhoneField() : renderOtpField()}
                    </Col>
                    <Col md="3 text-right pr-0">
                        <p className="w-100 fs-13-f-n">&nbsp;</p>
                        {!isPhoneValidated() ? <button ref={ref5} onClick={handleClick} disabled={checkValidity()} className="btn btn-success bgpinbutton">Next</button> : ""}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = state => ({
    phone:state.UserData.phone,
    customerDetails:state.UserData.customerDetails,
    otpSendStatus:state.UserData.otpSendStatus,
    valiDated_Otp:state.UserData.valiDated_Otp,
    valid_otp:state.UserData.valid_otp,
    companyid: state.HomeData.companyid,
	pin: state.LocationData.pin
})

export default connect(mapStateToProps, { set_phone, getCustomerDetails, validateOtp, setValidateOtp, sendOtp, clearOtpStatus })(PhoneValidate);  