import React, { useState } from 'react';
import ImageUtil from '../../utils/ImageUtil';
import { useNavigate } from 'react-router-dom';
import { clearCategory } from '../../actions/CategoryAction';
import { connect } from 'react-redux';

const Search = (props) => {

    const [input, setInput] = useState('');
    const navigate = useNavigate();
    
    function handleClick(e){
        
        e.preventDefault();
        props.clearCategory();
        navigate('/search', {state:{term:input}});

        setInput(''); //.. clear input field
    } 

    return (
        <div className="search">
            {/*eslint-disable-next-line*/}
            <a className="mobile-search"
                
                onClick={handleClick}>
                <img
                    src={ImageUtil.getImage('search-green.png')}
                    alt="search"
                />
            </a>

            <form
                action=""
                method="get"
                className={ "search-form active"}
            >
                <input
                    type="search"
                    placeholder="Search for Item"
                    className="search-keyword"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                />
                <button
                    className="search-button text-light"
                    type="submit"
                    onClick={handleClick}><i className="fa fa-search"></i>
                </button>
                
            </form>
            {/* <p className="tagline">GET DAILY NECESSITY AT YOUR HOME WITHIN 24 HOURS OF PLACING YOUR ORDER</p> */}
        </div>
    )
}

export default connect(null, {clearCategory}) (Search);