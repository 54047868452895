

/**
 * All APIs for the application
 */
const API = {};

API.GET_COMPANY_INFO = 'Generic/GetCompany?url=';

API.GET_IP = 'https=//ipinfo.io?token=c49d0e3562b4b7';

API.GET_FEATURE_ITEMS = 'Inventory/GetFeatureItems?companyid=';

API.VALIDATE_PIN = 'Generic/ValidatePinCode';
API.GET_CATEGORY = 'Inventory/GetAllCategoriesV2?companyid=';
API.GET_ALL_ITEMS = 'Inventory/GetAllItems?companyid=';
API.GET_CUSTOMER_DETAILS = 'Order/GetCustomerDtlV2?'; //Order/GetCustomerDtl
API.GET_ITEMS_BY_VARIANT = 'Inventory/GetItemsByVariantIds';
API.GET_CONFIG='/config.json';

API.SEND_OTP = 'Sms/SendOtp';
API.VALIDATE_OTP = 'Sms/Verifyotp';

API.VALIDATE_DISCOUNT = 'order/ValidateDiscount';
API.CHECKOUT_ORDER = 'Order/CheckOutOrder';
API.PLACE_ORDER = 'Order/PlaceOrder';

export default API
