import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
    set_fname,
    set_lname,
    set_email,
    set_address1,
    set_address2,
    set_city,
    set_dist,
    set_state,
    set_country, set_pin, set_addressType, set_addressId, setCustomerDetails
} from '../../actions/UserAction';



const Address = (props) => {

    const emptyForm = {
        addrid:-1,
        email: "",
        fname: "",
        lname: "",
        address1: "",
        address2: "",
        city: "",
        dist: "",
        state: "",
        pin: props.pincode,
        type: ""
    }
    
    const [formData, setFormData] = useState(emptyForm);
    const [isFormOpen, setFormOpen] = useState(false);
    const [validated, setValidated] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);

    const { email, fname, lname, address1, address2, city, state, pin, dist, type } = formData;
    

    const setFinalAddress = (item) => {
        //+++++++++++++++++++++++++
        props.set_fname(item.fname);
        props.set_lname(item.lname);
        props.set_email(item.email);
        props.set_address1(item.address1);
        props.set_address2(item.address2);
        props.set_city(item.city);
        props.set_state(item.state);
        props.set_dist(item.dist);
        props.set_pin(item.pin);
        props.set_addressType(item.type);
        props.set_addressId(item.addrid)
        //+++++++++++++++++++++++++
    }
    const selectAddress = (data) => {
        setFormData(data)
        setFinalAddress(data);
        setAddressSelected(true);
        
        setTimeout(() => { 
            props.setAddressCard(false); 
            props.setDeliveryCard(true); 
        }, 500);
    }
    
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    //.. Call for NEW ADDRESS  
    const addAddressHandler = () => {
        setFormData({...emptyForm, state: props.state, dist: props.dist, city: props.city, address2: props.address2, pin:props.pincode});
        //handleOnChange({target:{name:"dist", value:props.dist}});
        setFormOpen(true);
    }

    //.. call for EDIT ADDRESS
    const editAddressHandler = (data,i) => {
        //console.clear();
        //.. edit address set the form to open
        setFormOpen(true);
        setFormData({...data});
    }

    const saveAddress = (event) => {
        //console.log("saving Customer details");
        //document.getElementById("addtype").options[1].selected = true;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        
        setValidated(true);
        event.preventDefault();

        if (form.checkValidity() === true) {
            //.. Save DETAILS OF THE FORM
            setFormOpen(false);
            doLocalUpdate(formData); // to be replaced with updateAddress call to server
        }
    }

    const doLocalUpdate = (data) => {
        //console.log(data.addrid, "->", data, props.customerDetails);
        if(data.addrid === -1){
            props.setCustomerDetails({data: props.customerDetails.add(data)});
        }else{
            let updatedData = props.customerDetails.map(function(item) { return item.addrid === data.addrid ? data : item; });
            props.setCustomerDetails({data: updatedData});
        }
    }

    const isReturningUser = () => {
        //return (props.customerDetails.length>0) ? true : false
        return isFormOpen || props.customerDetails.filter(address => {
            return address.address1 !== null && 
                    address.address2 !== null && 
                    address.city !== null && address.dist !== null && 
                    address.email !== null
                }).length === 0
    }

    const openForm = () => {
        //console.log("openForm");
        return (
            <Form noValidate validated={validated} onSubmit={saveAddress} className="mb-0">
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control placeholder="First Name" value={fname}
                                        name="fname" onChange={handleOnChange} required />
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control placeholder="Last Name" value={lname}
                                        name="lname" onChange={handleOnChange} required />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email" value={email}
                                    name="email" onChange={handleOnChange} required />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control placeholder="Number & Street" value={address1}
                                    name="address1" onChange={handleOnChange} required />
                            </Form.Group>

                            <Form.Group >
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control placeholder="Number & Street" value={address2}
                                    name="address2" onChange={handleOnChange} />
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control value={city}
                                        name="city" onChange={handleOnChange} required />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>District</Form.Label>
                                    <Form.Control value={dist}
                                        name="dist" onChange={handleOnChange} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control value={state}
                                        name="state" onChange={handleOnChange} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>PIN</Form.Label>
                                    <Form.Control value={pin}
                                        name="pin" readOnly type="number" required />
                                </Form.Group>
                            </Form.Row>
                            
                            {/* <Form.Group>
                                <Form.Label>Special Request</Form.Label>
                                <Form.Control as="textarea" name="rqst" value={rqst} onChange={handleOnChange} rows="3" />
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Label>Address Type</Form.Label>
                                <Form.Control id="addtype" onChange={selectAddressType}
                                    as="select" > 
                                    <option>Address Type</option>
                                     {props.addressTypes.map(item => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.type}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                            <Col md="12 text-right p-0">
                            <button className="btn btn-danger" type="button" onClick={closeForm}>Cancel</button>&nbsp;&nbsp;
                            <button className="btn btn-success bgpinbutton" type="submit">Save</button>
                            </Col>
                        </Form>
        )
    }

    const closeForm = () => {
        //.. set the form to close
        setFormOpen(false);
        //console.log("closeForm");
    }


    const renderAddresses = () => {
        let list = []
        list = props.customerDetails.map((item, i) => {
            //console.log("list ", props.customerDetails);
			if(item.fname!=null && item.lname!=null && item.address1!=null && item.address2!=null)
             return (
                 <Col md="6" className="pl-0 mb-1" key={i}>
                     <Card>
                         <Card.Header>
                             <p>
                                 <span className="float-right" style={{ cursor: "pointer"}} onClick={()=>editAddressHandler(item,i)}> Edit &nbsp;<i style={{ margin: "2px" }} className="fa fa-pencil float-right"></i></span>
                             </p>
                         </Card.Header>
                         <Card.Body>
                                 
                             <Row>
                                 <Col className="pl-0">{`${item.fname} ${item.lname}, ${item.address1}, ${item.address2 || ""} ${item.city}, ${item.state}, ${item.pin}`}</Col>
                             </Row>
                                 
                         </Card.Body>
                         <Card.Footer className="p-0">
                             <button className="btn btn-success btn btn-primary w-100 br-0 bgpinbutton" onClick={() => selectAddress(item)}>Deliver Here</button>
                         </Card.Footer>
                     </Card>
                 </Col>
             )
         })
         return list;
     } 
    
    return (
        <React.Fragment>
            <Card className="mb-2">
                <Card.Header className="bgf2f2f2">
                    {addressSelected ? <i className="fa fa-check-circle text-success f-size22" aria-hidden="true"></i> : <label className="number-before-check">2</label>} &nbsp;
                    Address
                    {props.addressCard ? <button className="float-right btn btn-link" onClick={addAddressHandler}> + Add New Address</button> : "" }
                    {props.addressCard && addressSelected && fname ?
                        <div>
                            <Row>
                                <Col md="6 pl-0 mb-1">
                                    <Row>
                                        <Col className="pl-0">{`${fname} ${lname}, ${address1}, ${address2 || ""} ${city}, ${state}, ${pin}`}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div> : ""}
                </Card.Header>
                <Card.Body style={{ display: props.addressCard ? "block" : "none" }}>
                    {isReturningUser() ? openForm() : renderAddresses()}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    pincode: state.LocationData.pin,
    address2: state.LocationData.area,
    city: state.LocationData.city,
    dist: state.LocationData.dist,
    state: state.LocationData.state,

    
    customerDetails: state.UserData.customerDetails,
    addressTypes: state.UserData.addressTypes
})

export default connect(mapStateToProps, { 
    setCustomerDetails,
    set_fname, set_lname, set_email, set_addressType,
    set_address1, set_address2, set_city, set_dist, set_state, set_country, set_pin, set_addressId
})(Address);