

// Description on const CATEGORYLIST

const ADDTOCART = "ADDTOCART";
const SETCARTBOUNCE = "SETCARTBOUNCE";
const ADDQUANTITY = "ADDQUANTITY";
const SUBTRACTQUANTITY = "SUBTRACTQUANTITY";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const CLEAR_CART = "CLEAR_CART";

const PREVIEW_CART = "PREVIEW_CART";
const CHECKEDOUT_CART = "CHECKEDOUT_CART";
//.....................................

//...................................................................
/**
 * Action addToCart defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const addToCart = param => ({
    type: ADDTOCART,
    payload: param
});

//...................................................................
/**
 * Action addQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const addQuantity = param => ({
    type: ADDQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action subtractQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const subtractQuantity = param => ({
    type: SUBTRACTQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action subtractQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const removeItem = param => ({
    type: REMOVE_FROM_CART,
    payload: param
});

//...................................................................
/**
 * Action clearCart defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const clearCart = () => ({
    type: CLEAR_CART,
    payload: null
});

//...................................................................
/**
 * Action setCartBounce defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setCartBounce = param => ({
    type: SETCARTBOUNCE,
    payload: param
});


//...................................................................
/**
 * Action Preview cart .
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const previewCart = param => ({
        type: PREVIEW_CART,
        payload: param
});
//...................................................................
/**
 * Action setCheckOutCartState >> cart clicked for checkout .
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setCheckOutCartState = param => ({
    type: CHECKEDOUT_CART,
    payload: param
});
//...................................................................


//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    preview: false,
    totalItems: 0,
    totalAmount: 0,
    cartBounce: false,
    cartList: [],
    checkOutClicked: false
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case ADDTOCART: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount, cartBounce: true }
        }
        case ADDQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount }
        }
        case SUBTRACTQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case REMOVE_FROM_CART: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case CLEAR_CART: {
            return {...state, cartList: [], totalItems: 0, totalAmount: 0} 
        }
        case PREVIEW_CART: {
            return {...state, preview: action.payload} 
        }
        case CHECKEDOUT_CART: {
            return {...state, checkOutClicked: action.payload} 
        }
        default:
            return state;
    }
}

//...................................................................
//.. selectors 

//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const CartAction = {
    reducer: reducer,
    actions: {
        addToCart,
        addQuantity,
        subtractQuantity,
        setCartBounce,
        removeItem
    }
}

export default CartAction