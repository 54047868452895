import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Col } from 'react-bootstrap';
import PreviewItemList from '../components/cartpreview/PreviewItemList';
import { previewCart, setCheckOutCartState } from '../actions/CartAction';
import { setPinModal } from "../actions/LocationAction";
import { selectCheckOutJSON, checkOutOrder, clearOrder } from '../actions/CheckoutAction';
import { to2Decimal, formatINR } from '../utils/CommonUtil';
import { Global } from '../utils/Env';

const CartPreviewModal = props => {

    const navigate = useNavigate();
    
    useEffect(() => {
        props.previewCart(false);
        Global.callback.checkOutOrder_onComplete = () => {
            navigate("/checkout", { state: { process: true } });
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.previewCart, navigate]);

    const checkoutHandler = () => {
        onHide();
        if (props.deliverablePin) {
            //props.cleanDiscount();
            //props.clearOrder();
            props.checkOutOrder(props.checkoutData);
        } else {
            props.setCheckOutCartState(true);
            props.setPinModal(true);
        }
    }

    const onHide = () => {
        props.previewCart(false)
    }

    let hasItems = () => {
        return (props.cartItems && (props.cartItems.length > 0)) ? true : false
    }

    return (
        <React.Fragment>
            <Col>
                <Modal show={props.preview} onHide={onHide} className="cart-modals">
                    <Modal.Header closeButton>
                        <Modal.Title>My Cart ({props.cartItems.length})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 bg-light-modal hc-modal">
                        <PreviewItemList />
                    </Modal.Body>
                    <Modal.Footer>
                        <p className="font-12 text-center w-100 text-info">Promo code can be applied on payment page</p>
                        <div className="chk-out-button-mobal w-100">
                            <button
                                type="button"
                                onClick={checkoutHandler}
                                disabled={!hasItems()}
                                className={hasItems() ? "chk-out-button-mobal w-100" : "chk-out-button-mobal w-100 checkout-disable"}>
                                Proceed to Checkout
                                <span className="float-right">{formatINR(to2Decimal(props.totalAmount))}</span>
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Col>
        </React.Fragment>
    );

}

const mapStateToProps = state => ({
    preview: state.CartData.preview,
    cartItems: state.CartData.cartList,
    totalAmount: state.CartData.totalAmount,
    checkOutClicked: state.CartData.checkOutClicked,
    deliverablePin: state.LocationData.deliverablePin,
    checkoutDetails: state.CheckoutData.checkoutDetails,
    checkoutData: selectCheckOutJSON(state),
})

export default connect(mapStateToProps, { previewCart, setCheckOutCartState, setPinModal, checkOutOrder, clearOrder })(CartPreviewModal);  