import React from 'react';
import {config} from '../../config';
import { connect } from 'react-redux';
//import Actions, { DispatchAction } from '../actions';
import { selectCheckOutJSON, checkOutOrder } from '../../actions/CheckoutAction';
import { setPinModal } from "../../actions/LocationAction";
import { setNotify } from "../../actions/UserAction";
import { Global } from '../../utils/Env';

const Location = (props) => {

    Global.callback.validatePin_onComplete = () => {
        console.log("validatePin completed -> ", props);
        if(!props.deliverablePin){
            onPinClick();
			props.setNotify(props.msg);
        }else{
            if(props.checkOutClicked){
                props.checkOutOrder(props.checkoutData);
            }
        }
    }

    const onPinClick = () => {
        //DispatchAction(Actions.setPinModal(true)); OR the one below
        props.setPinModal(true);
    }

    return (
        <div className="location-part">
            <div className="">
                <div className="pin-code lh-15" onClick={onPinClick}>
                    <label className="mb-0 align-top">
                        <div className={!props.deliverablePin ? "notification-indicator":""}>
                            <span><i className="fa fa-map-marker fa-2x map-icon" aria-hidden="true"></i></span>
                        </div></label>&nbsp;&nbsp;
    <label className="ziptext mb-0 font-12">Deliver to<br/><label className={(props.deliverablePin)?("zipname mb-0 fs-14"):("zipname_err mb-0 fs-14")}>{props.pin}</label></label>
   
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    pin:state.LocationData.pin,
    deliverablePin:state.LocationData.deliverablePin,
    city: state.LocationData.city,
    msg: state.LocationData.msg,
    checkOutClicked: state.CartData.checkOutClicked,
    checkoutData: selectCheckOutJSON(state)
})
export default connect(mapStateToProps, {checkOutOrder, setPinModal,setNotify})(Location);