import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';
import Logo from './Logo';
import Cart from './Cart';
import Search from './Search';
import Location from './Location';
import CategoryMenu from '../category/CategoryMenu';
import { toggleMenuState } from '../../actions/CategoryAction';

const Header = props => {

    const renderPhone = () => {

         let mobileNum = null;
        if (props.phone!=='' && props.phone !== null && typeof props.phone === 'object') {
            if(props.phone.hasOwnProperty('custservice')===true){
            if(typeof props.phone.custservice==='string' && props.phone.custservice.includes('/')===true){
            let array = props.phone.custservice.split('/');

            if (props.mobileState) {
                mobileNum = (
                    <React.Fragment>
                        {array[0]}
                        <br />
                        <span className="alt_mobile_no">
                            {array[1]}
                        </span>
                    </React.Fragment>
                );
            } else {
                mobileNum = `${array[0]} / ${array[1]}`;
            }
        }else{
            mobileNum = props.phone.custservice;
            }
        }
    }
        return props.phone ? mobileNum : null ;
    }

    const displayHeader = () => {
        return (
            <React.Fragment>
                <Row className="topsection-header">
                    <Col md={2} xs={3} className="text-center mb-10-mob"><Logo /></Col>
                    <Col md={2} xs={8} className="pl-0-mob p-0 font-12 mb-10-mob colorfff"><i className="fa fa-phone-square fs-14" aria-hidden="true"></i>&nbsp;&nbsp;<b className="no-mobfont">{renderPhone()}</b></Col>
                    <Col md={12} xs={2} className="mob-category pr-10-mob mb-10-mob pl-0-mob">

                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="for-mob-control-hamberger" />
                        <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>

                    </Col>

                    <Col md={5} xs={10} className=" pl-0-mob mb-10-mob"><Search /></Col>

                    <Col md={1} xs={3} className="p-0-tab p-0 text-center mob-location-custom mb-10-mob"><Location /></Col>
                    <Col md={1} xs={3} className="p-0-tab p-0 text-center mob-location-custom mb-10-mob"><Cart /></Col>
                    
                </Row>

                <Row className="topsection-category p-0-xs p-0-tab w-100-mob">
                    <Col className="p-0-xs p-0-tab">
                        <Container className="p-0-xs">
                            <Row className="w-100-mob">
                                <Col className="p-0-mob"><CategoryMenu /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="container p-0-mob">
                {(props.mobileState) ? (<Navbar bg="white" sticky="top" expand="lg border-bottom" className="navstylecontrol" onToggle={() => props.toggleMenuState(!props.menuState)} expanded={props.menuState}> {displayHeader()}</Navbar>) : (displayHeader())}
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    phone: state.HomeData.phoneno,
    mobileState: state.CategoryData.isMobileMenu,
    menuState: state.CategoryData.menuState
})

export default connect(mapStateToProps, { toggleMenuState })(Header);