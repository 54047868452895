import React from "react";

import ImageUtil from '../utils/ImageUtil';

const EmptyCart = props => {
  return (
    <div className="empty-cart">
      <img
        src={ImageUtil.getImage('empty-cart.png')}
        alt="empty-cart"
      />
      <h2>You cart is empty!</h2>
    </div>
  );
};

export default EmptyCart;
