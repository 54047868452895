import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import Product from '../components/Product';
import {ScrollInView} from '../components/Scroll';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { selectsubsubCategory } from '../actions/CategoryAction';

const CategoryContainer = (props) => {
	const params=useParams();
	
const location = useLocation();

    const getcategory = (obj, id) => {
		let obj1=obj;
        for(var i=0;i<=id;i++)
		{
			if(typeof obj1.category !="undefined")
				obj1=obj1.category;
			else
				return false;
		}
		console.log(obj1.id);
		return obj1;
			
    }
	
	const itemlist = () => {
        let cat = (typeof props.selectedsubsubCategory != "undefined" && props.selectedsubsubCategory!='') ? ((props.selectedsubsubCategory.split(' ')[0].split(':')[1]!=Number(params.catid))?"category:".concat(Number(params.catid)):props.selectedsubsubCategory.split(' ')[props.selectedsubsubCategory.split(' ').length-1]) :((typeof params.subcatid != "undefined" && params.subcatid!='')?'sub_category:'.concat(Number(params.subcatid)):'category:'.concat(Number(params.catid)));
		console.log(cat);
        let final_item_list = props.productsList;
        if ( typeof final_item_list != "undefined" && cat !=0) {
                final_item_list = final_item_list.filter(item => { return (typeof item[cat.split(':')[0]]!="undefined")?(item[cat.split(':')[0]] == cat.split(':')[1]):false });
        }
        return final_item_list;

    }

    
    const categoryList = (itemlist());



    const renderProducts = () => {
        let productsData;
		if(categoryList)
		{
        productsData=categoryList.map((product) => {
                    const cartObj = props.cartList.filter(item => {
                        return item.id === product.id
                        })
                    return (<Product
                        key={product.id}
                        product={product}
                        cart={cartObj[0]}
                    />)
                })
		}
        return productsData;
      }
      
    let categoryView = renderProducts()
        
    return (
        <ScrollInView trigger={props.selectedCategory}>
            <Container className="p-0-mob">
                <div className="products-wrapper">  
                    <div className="row">
                    {categoryView}
                    </div>
                </div>
            </Container>    
        </ScrollInView>
    );

  }


const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    selectedCategory: state.CategoryData.selectedCategory,
	selectedsubsubCategory: state.CategoryData.selectedsubsubCategory,
    cartList: state.CartData.cartList
	
})

export default connect(mapStateToProps,{selectsubsubCategory})(CategoryContainer);  