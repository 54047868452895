import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ImageUtil from '../../utils/ImageUtil';
import Loading from '../Loading';

const Logo = props => {
    const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const css={width:'80px',height:'40px'};
    const OnClickandler = () => {
        navigate(`/`);
    }
    return (
		<React.Fragment>
        <div className={loading ? "brand show" : "brand hide"}  onClick={OnClickandler}>
            {props.logo ? <img
                className="logo"
                src={ImageUtil.getImage(props.logo.src)}
                alt= {props.logo.alt}
				onLoad={() =>setLoading(true)}
            /> : ''}
        </div>
		<div className={loading ? "hide" : "show"} onClick={OnClickandler} >
            <Loading css={css} />
        </div>
		</React.Fragment>
    )
}

const mapStateToProps = state => ({
    logo:state.HomeData.logo
})

export default connect(mapStateToProps, {})(Logo);