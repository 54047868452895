import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import ImageUtil from "../utils/ImageUtil";
import { formatINR } from '../utils/CommonUtil';
import Counter from "./Counter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getItemsByVariant, selectProduct } from '../actions/ProductsAction';
import { Global } from '../utils/Env';


const ProductDetails = (props) => {

  const navigate = useNavigate();
  const params = useParams();
  const [selectedProduct, setSelectedProduct] = useState({});

  const product = (props.productsList.filter(product => {
    return Number(product.id) === Number(params.slug)
  }))[0];

  

  //console.log("params ", params, product, selectedProduct);
  useEffect(() => {
    if (product) {
      props.getItemsByVariant({ companyid: props.companyid, variant: product.variant })
    }
  },
    // eslint-disable-next-line
    [product])
	
	Global.callback.itemsbyvariant_onComplete = () => {
		setSelectedProduct(props.variantList[0]);
	}


  const onMoreShopping = () => navigate(-1);

  //console.log(props.variantList, product, selectedProduct)
  const handleVarientChange = (product) => {
    setSelectedProduct(product)
  }
  const renderImages = () => {
    let imageList;
    if (selectedProduct.images[0]) {
      imageList = selectedProduct.images.map((item, id) => {
        return (
          <div className="quick-view-image" key={id}>
            <img className="quick-view-product-image main-imgproduct-image"
              src={ImageUtil.getImage(item.name)}
              alt={item.name}
            />
            {selectedProduct.brandicon ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={selectedProduct.brandicon} src={ImageUtil.getImage(selectedProduct.brandicon)} /></div> : null}

          </div>
        )
      })
    }
    else {
      imageList = (<div className="quick-view-image">
        <img className="quick-view-product-image"
          src={ImageUtil.getErrorImage()}
          alt=""
        />
        {selectedProduct.brandicon !== "" ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={selectedProduct.brandicon} src={ImageUtil.getImage(selectedProduct.brandicon)} /></div> : null}

      </div>
      )
    }
    return imageList;
  }
  if (Object.keys(selectedProduct).length > 0) {
    return (
      <React.Fragment>
        <div className="action-block action-blockpopup pos-fixedclass">
          <Container>
            <div className="chk-out-button  text-left bx-shadow-withbord pl-0 pr-0">
              <div className="product-links-anchor cursor" onClick={onMoreShopping}>Back to Shopping</div>
            </div>
          </Container>
        </div>
        <Container>
          <Row className="product-details-maindiv">

            <Col md="5">
              <Carousel thumbWidth={"50px"}>
                {renderImages()}
              </Carousel>
            </Col>
            <Col md="7">
              <div className="quick-view-description">
                <h3 className="product-description-mainname sl-prodname">{selectedProduct.name}</h3>
                <span className="badge badge-danger bg-red font-weight-normal mr-5">{selectedProduct.promo_percentage ? `${selectedProduct.promo_percentage} Off` : ""}</span>
                <span className="badge badge-pill badge-danger bg-red font-weight-normal p-1 pl-2 pr-2">{selectedProduct.promo_name || ""}</span>
              </div>
              <div className="quick-view-details">


                <div className="mt-3">
                  <p className="mb-1">Available in:</p>
                  <div className="variant-select-btn-control">
                    {props.variantList.map(item => (<button key={item.variantid} className={item.variantid === selectedProduct.variantid ? "active-variant-btn" : ""} onClick={() => handleVarientChange(item)}>{item.size}</button>))}
                  </div>
                </div>
                {selectedProduct.promo_price ? <p className="pt-2 text-muted">Product MRP: <s>{formatINR(selectedProduct.price_regular)}</s></p> : ""}
                <p className="pt-2 pb-2">Selling Price: <span className="product-price product-price-product-details text-left mt-4 mb-4">{selectedProduct.promo_price ? formatINR(selectedProduct.promo_price) : formatINR(selectedProduct.price_regular)}</span></p>
                {selectedProduct.price_member ? <button className="btn btn-outline-success btn-sm mb-3">Price for Club Members:<b>{formatINR(selectedProduct.price_member)}</b></button> : ""}
                <div className="add-button">
                  <Counter product={selectedProduct} />
                </div>
                <div className="details-description-section">
                  <div className="mt-4 mb-4"><b>Item Description: </b><div className="product_details_cont" dangerouslySetInnerHTML={{ __html: selectedProduct.desc }} /></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
  else {
    return ""
  }
}

const mapStateToProps = state => ({
  productsList: state.ProductsData.productsList,
  product: state.ProductsData.selectedProduct,
  variantList: state.ProductsData.variantList,
  companyid: state.HomeData.companyid
})

export default connect(mapStateToProps, { getItemsByVariant, selectProduct })(ProductDetails);