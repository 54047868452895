import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { set_special_request } from '../../actions/CheckoutAction';

const Delivery = (props) => {
    const [completed, setCompleted] = useState(false);
    const [check, setCheck] = useState(true);
    const [rqst, setRqst] = useState("");

    useEffect(() => {
        if(props.special_request) {
            setRqst(props.special_request);
        }
    }, [props.special_request])
    
    const handleOnChange = e => {
        setRqst(e.target.value)
    }
    const nextHandler = () => {
        props.set_special_request(rqst);
        setTimeout(()=>{props.setDeliveryCard(false);props.setPymentCard(true);}, 500)
        setCompleted(true)
    }
    return (
        <React.Fragment>
            <Card className="mb-2">
                <Card.Header className="bgf2f2f2">
                    {completed ? <i className="fa fa-check-circle text-success f-size22" aria-hidden="true"></i> : <label className="number-before-check">3</label>} &nbsp;
                        Delivery Details
                </Card.Header>
                <Card.Body style={{ display: props.deliveryCard ? "block" : "none" }}>
                    <Row>
                        {props.deliveryslot && <Col md="12 p-0">
                            <input type="checkbox" checked={check} onChange={() => setCheck(!check)} />&nbsp;&nbsp;
                        <label>{props.deliveryslot}</label>
                        </Col>}
                        <Form.Label className="mb-12">Special Request</Form.Label>
                        <Form.Control className="mb-12" as="textarea" name="rqst" value={rqst} onChange={handleOnChange} rows="2" />
                    </Row>
                    <Row>
                        <Col md="12 text-right p-0">
                            <div style={{ paddingTop: '.5rem' }}>{<button className="btn btn-success bgpinbutton" disabled={!check} onClick={nextHandler}>Next</button>}</div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    deliveryslot: state.CheckoutData.checkoutDetails.deliveryslot,
    special_request: state.CheckoutData.special_request
})

export default connect(mapStateToProps, { set_special_request })(Delivery);
