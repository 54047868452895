import React, { useState } from 'react';
import { connect } from 'react-redux';
import GPayButton from 'react-google-pay-button';
import { Card, Row, Col, Nav } from 'react-bootstrap';
import { paymentMethods, loadPaymentDataHandler } from '../../utils/GPayGateway';
import PromoModal from '../../components/checkout/PromoModal';
import { to2Decimal, formatINR, hasProps } from '../../utils/CommonUtil';
import { selectPlaceOrderJSON, placeOrder } from '../../actions/CheckoutAction';

const Payment = (props) => {

    const [paymentType, setPaymentType] = useState('COD');
    const paymentTypeChangeHandler = (eventKey) => {
        setPaymentType(eventKey)
    }

    const onOrderClicked = () => {
        props.placeOrder(props.placeOrderData);
    }

    const evaluateVoucherMsg = () => {
        //console.log("evaluateVoucherMsg called");
        return  props.selected_voucher.valid 
                    ? <p>{props.selected_voucher.msg}</p> 
                    : <p>{props.selected_voucher.msg}</p>     
    }

    return (
        <Card className="mb-2">
            <Card.Header className="bgf2f2f2">
                {<label className="number-before-check">4</label>} &nbsp;
                Payment
            </Card.Header>
            <Card.Body style={{ display: props.paymentCard ? "block" : "none" }}>

                <div className="text-right text-muted">
                    <span className="fs-13-f-n">{props.checkoutDetails.date}</span>
                </div>
                <Card className="mb-3 p-2">
                    <div><span className="fs-13-f-n">Total Amount </span><b className=" fs-14 float-right">{formatINR(to2Decimal(props.checkoutDetails.totalamt))}</b></div>
                    <div><span className="fs-13-f-n">Total Tax Amount </span><b className=" fs-14 float-right">{formatINR(to2Decimal(props.checkoutDetails.totaltaxamt))}</b></div>
                    
                    <div>
                        <span className="fs-13-f-n">
                            Total Discount Amount </span>
                            <b className=" fs-14 float-right">
                                {hasProps(props, 'discountDetails') 
                                    ? formatINR(to2Decimal(props.checkoutDetails.discount_total)) 
                                    : '0.00'}
                            </b>
                        <span className="fs-14">
                            ({props.checkoutDetails.discount_percentage}%)
                        </span>
                    </div> 
                    
                    <div><span className="fs-13-f-n">Delivery Charges </span><b className=" fs-14 float-right">{formatINR(to2Decimal(props.checkoutDetails.deliverychargeamt))}</b></div>
                    <div className="grand-total"><span className="fs-13-f-n">Grand Total Amount</span><b className=" fs-14 float-right">{formatINR(to2Decimal(props.checkoutDetails.grandtotalamt))}</b></div>
                    {props.checkoutDetails.deliverytxt && <span className="fs-13-f-n">{props.checkoutDetails.deliverytxt}</span>}
                </Card>

                <div className="text-right pr-0">
                    <PromoModal />
                </div>

                {hasProps(props.selected_voucher, 'valid') && <div className="alert alert-warning" role="alert">
                    {evaluateVoucherMsg()}
                </div>}

                {/*<Row>
                    <Col>
                        <Nav variant="pills" activeKey={paymentType} onSelect={paymentTypeChangeHandler}>
                            <Nav.Item>
                                <Nav.Link eventKey="COD">
                                    COD
                                </Nav.Link>
                            </Nav.Item>
                             <Nav.Item>
                                <Nav.Link eventKey="GPAY">
                                    G-PAY
                                </Nav.Link>
                            </Nav.Item> 
                        </Nav>
                    </Col>
                </Row>*/}


                {paymentType === "GPAY" ? <div className="text-right"><GPayButton

                    totalPriceStatus={'FINAL'}
                    totalPrice={`${props.checkoutDetails.grandtotalamt}`}
                    currencyCode={'INR'}
                    countryCode={'IN'}
                    allowedPaymentMethods={paymentMethods()}
                    development={true}
                    type={'short'}
                    merchantInfo={{
                        merchantName: '<YOUR MERCHANT NAME>',
                        // A Google merchant identifier issued after your website is approved by Google ✅
                        merchantId: '<YOUR MERCHANT ID>'
                    }}
                    onLoadPaymentData={loadPaymentDataHandler}
                /></div> : <div className="text-right"><button className="btn btn-success themebg" onClick={onOrderClicked}>PLACE ORDER</button></div>}
                <Row>
                    <Col className="text-info text-right mt-2 p-0">Payment Instructions will be provided to you over Phone and Email</Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = state => ({
    companyid: state.HomeData.companyid,
    pincode: state.LocationData.pin,
    totalAmount: state.CartData.totalAmount,
    cartList: state.CartData.cartList,
    checkoutDetails: state.CheckoutData.checkoutDetails,
    discountDetails: state.CheckoutData.discountDetails,
    selected_voucher: state.CheckoutData.selected_voucher,
    placeOrderData: selectPlaceOrderJSON(state)

})
export default connect(mapStateToProps, { placeOrder })(Payment);