import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

import {host} from '../utils/Env';

const CONFIG_INFO = "CONFIG_INFO";
const COMPANY_ID = "COMPANY_ID";

/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCompanyId = () => {
    console.log(`HOST ${host()}`);
    return apiAction({
        url: `${API.GET_COMPANY_INFO}${host()}`, 
        onSuccess: setCompanyId,
        onFailure: () => console.log("Error occured in getCompanyId"),
        label: COMPANY_ID
      });
}

export const getConfigInfo = () => {
    return apiAction({
        url: window.location.origin.toString().concat(API.GET_CONFIG), 
        onSuccess: setConfigInfo,
        onFailure: () => console.log("Error occured in getConfigInfo"),
		callback: 'getConfigInfo',
        label: CONFIG_INFO
      });
}

export const setConfigInfo = param => ({
    type: CONFIG_INFO,
    payload: param.data.company
  });
  
  export const setCompanyId = param => ({
    type: COMPANY_ID,
    payload: param.data.companyid
  });


/**
 * set up with initial state.
 */
const initialState = {
    companyid: ''
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        //case COMPANY_ID: return {...state, companyid: action.payload.companyid, logo:action.payload.logo }
        case CONFIG_INFO: return {...state, ...action.payload }
		case COMPANY_ID: return {...state, companyid:action.payload }
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const HomeAction = {
    reducer: reducer,
    actions: {
        getConfigInfo,
		getCompanyId
    }
}

export default HomeAction