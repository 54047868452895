import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
/**
 * Creates a new MsgModal.
 * @class
 */
const MsgModal = (props) => {
    
    useEffect(()=>{
        props.openAction();
    }, [])
    
    return (
        <React.Fragment>
            <Modal show={props.show} onHide={props.closeAction} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {typeof props.message === 'object' 
                        ? <div>{props.message}</div>
                        : <div dangerouslySetInnerHTML={{__html:props.message}}/>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.closeAction}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

MsgModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    show: PropTypes.bool.isRequired,
    openAction: PropTypes.func,
    closeAction: PropTypes.func,
};

MsgModal.defaultProps = {
    show: false,
    openAction: () => {},
    closeAction: () => {},
}

export default MsgModal
