import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

//.. Defining Location Action const

const DELIVERABLEPIN = "DELIVERABLEPIN";
const PINMODAL = "PINMODAL";

const IP_ADDRESS = "IP_ADDRESS";
//.....................................
/**
 * Action setPinModal defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 

export const setPinModal = (param) => ({
    type: PINMODAL,
    payload: param
});


//...................................................................
/**
 * Action validatePin defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */  

export const validatePin = (obj) => {
    return apiAction({
        url: API.VALIDATE_PIN,
        method: "post",
        data: obj,
        onSuccess: setValidatePin,
        onFailure: () => console.log("Error occured in validatePin"),
        callback: "validatePin",
        label: DELIVERABLEPIN
      });
}

export const setValidatePin = param => {
    return ({
    type: DELIVERABLEPIN,
    payload: {...param.data, msg: param.Message}
})}

/**
 * Action getIPLocation  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */  

export const getIP = () => {
    return apiAction({
        url: API.GET_IP, 
        onSuccess: setIP,
        onFailure: () => console.log("Error occured in validatePin"),
        label: IP_ADDRESS
      });
}

export const setIP = param => {
    return ({
    type: IP_ADDRESS,
    payload: param
})}

//...................................................................

/**
 * set up with initial state.
 */
const initialState = {

    ip:'',
    loc:'',

    region:'',
    city:'',
    country:'',
    state:'',
    dist:'',
    area:'',

    postal:'',
    pin: '',
    
    deliverablePin: false,
    pinModalState: false,

    msg: ''
}
    
/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */ 
let reducer = (state = initialState, action) => {
	switch(action.type) {
        case IP_ADDRESS: return {...state, 
            ip: action.payload.ip, 
            loc: action.payload.loc, 
            region: action.payload.region, 
            city: action.payload.city, 
            country: action.payload.country,
            postal: action.payload.postal
        }
        case DELIVERABLEPIN: return {...state, 

            area: action.payload.area,
            country: action.payload.country,
            dist: action.payload.dist,
            pin:action.payload.pincode,
            postal: action.payload.pincode,
            city: action.payload.region, 
            region: action.payload.region,
            state: action.payload.state, 
            deliverablePin: action.payload.exist,
            msg: action.payload.msg
        }

        case PINMODAL: return {...state, pinModalState: action.payload } 
        default: return state;
	}
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const LocationAction = {
    reducer:reducer,
    actions:{
        validatePin, 
        setPinModal
    }
}

export default LocationAction
 