import React from 'react';
import { connect } from 'react-redux';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';
//import ImageUtil from '../../utils/ImageUtil';
import {previewCart} from '../../actions/CartAction';

import cartIcon from '../../assets/img/bag.png';

const Cart = (props) => {
    
    const onCartClicked = () => {
        //props.checkOutOrder(props.checkoutData);
        props.previewCart(true);
    }
    
    return (
        <div className="row">
            <div className="cart">
                <div className={props.deliverablePin ? "cart-icon cursor d-inline-block cart-icon-blank-notification" : "cart-icon cursor d-inline-block"} onClick={onCartClicked}>
                    <img
                        className={props.cartBounce ? "tada" : " "}
                        src={cartIcon}
                        alt="Cart" />   
                    {props.totalItems ? (<span className="cart-count">{props.totalItems}</span>) : ("")}
                </div> 
                <div className="cart-info">
                    <p className="mt-3 ml-3 amount-setdiv"> <b>{formatINR(to2Decimal(props.totalAmount))}</b></p>
                </div>       
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    totalItems:state.CartData.totalItems,
    totalAmount:state.CartData.totalAmount,
    cartBounce:state.CartData.cartBounce,
    deliverablePin: state.LocationData.deliverablePin
  })

export default connect(mapStateToProps, { previewCart }) (Cart);