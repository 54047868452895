import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ImageUtil from "../utils/ImageUtil";
import { addQuantity, subtractQuantity, addToCart, setCartBounce } from "../actions/CartAction";
import { connect } from "react-redux";

const Counter = props => {
  const [interv, setInterv] = useState(null);
  const { product } = props;
  const cart = props.cartList
  let index = cart.findIndex(x => x.variantid === product.variantid);

  const findProductQnty = () => {
    let quantity = 0;
    if (index > -1) {
      quantity = cart[index].quantity
    }
    return quantity;
  }

  const getTotalAmount = (x) => {
    let totalAmount = 0
    for (let i = 0; i < x.length; i++) {
      let price = (x[i].promo_price ? x[i].promo_price : x[i].price_regular)
      totalAmount += price * parseInt(x[i].quantity);
    }
    return totalAmount
  }
  const addToCart = () => {
    let cart = [...props.cartList];
    cart.push({ ...product, quantity: 1 });
    let totalAmount = getTotalAmount(cart)
    props.addToCart({ cart: cart, totalAmount: totalAmount });
    setTimeout(() => props.setCartBounce(false), 1000)
  }
  const add = (ob) => {
    props.addQuantity(ob);
  }


  const clear = () => {
    clearInterval(interv);
  }

  const minus = (ob) => {
    props.subtractQuantity(ob);
  }

  const handlePlus = () => {
    let cart1 = [...props.cartList];
    let item = { ...cart1[index], quantity: cart1[index].quantity + 1}
    cart1[index] = item;
    let totalAmount = getTotalAmount(cart1);
    add({ cart: cart1, totalAmount: totalAmount });
  }

  const handleMinus = () => {
    let cart1 = [...props.cartList];
    if (cart1[index].quantity > 1) {
      let item = { ...cart1[index], quantity: cart1[index].quantity - 1 }
      cart1[index] = item;
    }
    else
      cart1.splice(index, 1);
    let totalAmount = getTotalAmount(cart1);
    minus({ cart: cart1, totalAmount: totalAmount });
  }


  let quantity = findProductQnty()
  if (quantity > 0) {
    return (
      <div className={"stepperc-input checkoutstepperc-input"} style={{ display: quantity > 0 ? "flex" : "none" }}>
        {/* eslint-disable-next-line */}
        <a className="decrement" onClick={() => handleMinus()} >
          {quantity >= 1 ? <i className="fa fa-minus font-12" aria-hidden="true"></i> : <img alt="" src={ImageUtil.getImage("delete.svg")} />}
        </a>
        <input
          type="number"
          className="quantity"
          readOnly={true}
          value={quantity}
        />
        {/* eslint-disable-next-line */}
        <a className="increment" onClick={() => handlePlus()} >
          <i className="fa fa-plus font-12" aria-hidden="true"></i>
        </a>
      </div>
    );
  }
  else {
    clear();
    return <button className="addtocart-btn" onClick={addToCart}>ADD TO CART</button>
  }
}

Counter.propTypes = {
  value: PropTypes.number
};
const mapStateToProps = state => ({
  cartList: state.CartData.cartList,
});
export default connect(mapStateToProps, { addQuantity, subtractQuantity, addToCart, setCartBounce })(Counter);
