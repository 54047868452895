import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col, Card, Form, Container } from 'react-bootstrap';
import { setPromModal, selectValidateDiscountJSON, validateDiscount } from '../../actions/CheckoutAction';
import { hasProps } from '../../utils/CommonUtil';

const PromoModal = (props) => {


    const [promocode, setPromocode] = useState("")
    const [promoApplied, setPromoApplied] = useState(false);

    const handleClose = () => {
        props.setPromModal(false)
    }
    const handleOpen = () => {
        props.setPromModal(true)
    }
    
    const onPromoClicked = param => e => {
        setPromoApplied(true);
        setPromocode(param.code);
    }

    const handleApply = (event) => {
        event.preventDefault()
       let indx = (props.checkoutDetails.vouchers ? props.checkoutDetails.vouchers.findIndex(item => promocode.toLocaleLowerCase() === item.code.toLocaleLowerCase()) : -1);
       let vouchers={}
       if(indx !== -1) {
         vouchers = props.checkoutDetails.vouchers[indx]
       }
       else {
         vouchers={id:-1,code:promocode}
       }
       props.validateDiscount({...props.validateDiscountJSON, vouchers });
       setPromocode('');
    }


    return (
        <React.Fragment>
            <button className="w-100 btn btn-warning btn-sm mb-3 bgpinbutton" onClick={handleOpen}>Promo Code & Bank Offers</button>
            <Modal show={props.promoModal} onHide={handleClose}  centered>
                <Modal.Header closeButton className="bg-info pt-1 pb-1 text-white cl-btn-white">
                    <h5>Apply Promo</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Row>
                        <Form.Group as={Col}  md={8} xs={8}>
                            <Form.Control placeholder="Enter Promo Code" value={promocode}
                            onChange={(e) => setPromocode(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} md={4} xs={4} className="text-right">
                            <Button disabled={promocode.length > 2 ? false : true} type="submit" variant="success" onClick={(e)=>handleApply(e)}>Apply</Button>
                        </Form.Group>
                    </Form.Row>
                    </Form>
                    {hasProps(props, 'vouchers') ? <div>
                        {<Row>
                            <p className="mb-1 fs-14"><b>Click a Promo Code</b></p>
                        </Row>}
                        <Row>
                            {props.checkoutDetails.vouchers.map(item => (
                                <Col md={12} key={item.id} className="p-0 pb-1">
                                    
                                    <Card className="p-1 mb-1 bg-dark text-white" style={{cursor:"pointer"}}  >
                                        <button onClick={onPromoClicked(item)} >{item.code} - {item.description}<br/>
                                        <p className="font-12 text-success">{item.minimumamount}</p></button>
                                    </Card>
                                </Col>)
                            )}
                           
                        </Row>
                    </div> : <p>No Promo Code Available !</p>}
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    checkoutDetails: state.CheckoutData.checkoutDetails,
    promoModal: state.CheckoutData.promoModal,
    discountDetails: state.CheckoutData.discountDetails,
    validateDiscountJSON: selectValidateDiscountJSON(state)
})
export default connect(mapStateToProps, {setPromModal,validateDiscount})(PromoModal);