import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

/**
 * Constants defination 
 */
const USER_FIRST_NAME = "USER_FIRST_NAME";
const USER_LAST_NAME = "USER_LAST_NAME";
const USER_PHONE = "USER_PHONE";
const USER_EMAIL = "USER_EMAIL";
const USER_ADDRESS_1 = "USER_ADDRESS_1";
const USER_ADDRESS_2 = "USER_ADDRESS_2";
const USER_PINCODE = "USER_PINCODE";
const USER_CITY = "USER_CITY";
const USER_DISTRICT = "USER_DISTRICT";
const USER_STATE = "USER_STATE";
const USER_COUNTRY = "USER_COUNTRY";
const ADDRESS_TYPE = "ADDRESS_TYPE";
const ADDRESS_ID = "ADDRESS_ID";
const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
const OTP_VALIDATION = 'OTP_VALIDATION';
const CLEAR_DETAILS = "CLEAR_DETAILS";
const OTP_SEND_STATUS = "OTP_SEND_STATUS";
const CLEAR_OTP_STATUS = "CLEAR_OTP_STATUS";
const NOTIFICATION = "NOTIFICATION";
const GENERIC_MODAL = 'GENERIC_MODAL';
/**
 * Actions defination
 * @constructor
 * @param {*} param 
 */
export const set_fname = (param) => ({ type: USER_FIRST_NAME, payload: param })
export const set_lname = (param) => ({ type: USER_LAST_NAME, payload: param })
export const set_phone = (param) => ({ type: USER_PHONE, payload: param })
export const set_email = (param) => ({ type: USER_EMAIL, payload: param })
export const set_address1 = (param) => ({ type: USER_ADDRESS_1, payload: param })
export const set_address2 = (param) => ({ type: USER_ADDRESS_2, payload: param })
export const set_pin = (param) => ({ type: USER_PINCODE, payload: param })
export const set_city = (param) => ({ type: USER_CITY, payload: param })
export const set_dist = (param) => ({ type: USER_DISTRICT, payload: param })
export const set_state = (param) => ({ type: USER_STATE, payload: param })
export const set_country = (param) => ({ type: USER_COUNTRY, payload: param })
export const set_addressType = (param) => ({ type: ADDRESS_TYPE, payload: param })
export const set_addressId = (param) => ({ type: ADDRESS_ID, payload: param})
export const clear_userDetails = () => ({ type: CLEAR_DETAILS })
export const setNotify = (param) => ({ type: NOTIFICATION, payload: param})

export const setMsgModal = (param) => ({ 
    type: GENERIC_MODAL,
    payload: param
});
/**
 * Action getCustomerDetails defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const getCustomerDetails = (obj) => {
    return apiAction({
        url: API.GET_CUSTOMER_DETAILS+`CompId=${obj.companyid}&Phone=${obj.phone}&Pin=${obj.pin}`, 
        onSuccess: setCustomerDetails,
        onFailure: () => console.log("Error occured in getCustomerDetails"),
        callback: 'getCustomerDetails',
        label: CUSTOMER_DETAILS
      });
}

export const setCustomerDetails = param => {
    //console.log("setCustomerDetails ", param);
    return {
        type: CUSTOMER_DETAILS,
        payload: param
    }
  };

/**
 * Action validateOtp defined.
 * @constructor
 * @param {object} param - Pass param to update the payload.
 */
export const validateOtp = (obj) => {
    return apiAction({
        url: API.VALIDATE_OTP,
        method: "post",
        data: obj,
        onSuccess: setValidateOtp,
        onFailure: () => console.log("Error occured in validateOtp"),
        callback: 'validateOtp',
        label: OTP_VALIDATION
    });
}

export const setValidateOtp = param => ({
    type: OTP_VALIDATION,
    payload: param.valid
});

/**
 * Action sendOtp defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */ 
export const sendOtp = (obj) => {
    return apiAction({
        url: API.SEND_OTP, 
        method: "post",
        data: obj,
        onSuccess: setOtpSent,
        onFailure: () => console.log("Error occured in sendOtp"),
        callback: 'sendOtp',
        label: OTP_SEND_STATUS
      });
}

export const setOtpSent = param => ({
    type: OTP_SEND_STATUS,
    payload: param
  });


export const clearOtpStatus = param => ({
    type: CLEAR_OTP_STATUS,
    payload: param
});




/**
 * set up with initial state.
 */
const initialState = {
	notification: false,
    addrid: '',
    fname: '',
    lname: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    pin: '',
    city: '',
    dist: '',
    state: '',
    country: '',
    type: -1,
    addressTypes: [],
    customerDetails: [],
    valid_otp: false,
    valiDated_Otp: false,
    otpSendStatus: {} //hard coded for temporary test
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FIRST_NAME: return {...state, fname: action.payload }
        case USER_LAST_NAME: return {...state, lname: action.payload }
        case USER_PHONE: return {...state, phone: action.payload, valiDated_Otp: false }
        case USER_EMAIL: return {...state, email: action.payload }
        case USER_ADDRESS_1: return {...state, address1: action.payload }
        case USER_ADDRESS_2: return {...state, address2: action.payload }
        case USER_PINCODE: return {...state, pin: action.payload }
        case USER_CITY: return {...state, city: action.payload }
        case USER_DISTRICT: return {...state, dist: action.payload }
        case USER_STATE: return {...state, state: action.payload }
        case ADDRESS_ID: return {...state, addrid: action.payload }
        case USER_COUNTRY: return {...state, country: action.payload }
        case ADDRESS_TYPE : return {...state, type: action.payload }
        case CUSTOMER_DETAILS: return {...state, customerDetails: action.payload.data, addressTypes: action.payload.type}
        case OTP_VALIDATION: return {...state, valid_otp: action.payload, valiDated_Otp: action.payload}
        case OTP_SEND_STATUS: return {...state, otpSendStatus: action.payload}
        case CLEAR_OTP_STATUS: return {...state, otpSendStatus: {}}
        case CLEAR_DETAILS: return initialState
		case NOTIFICATION: return {...state, notification: action.payload}
		case GENERIC_MODAL: return { ...state, modal: action.payload }
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const UserAction = {
    reducer: reducer,
    actions: {
        sendOtp,
        getCustomerDetails,
        clear_userDetails,
        validateOtp
    }
}

export default UserAction