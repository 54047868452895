import React, {useEffect} from 'react';
//import {Alert, Row, Col } from 'react-bootstrap';
//import Actions, { DispatchAction } from '../actions';
import { connect } from 'react-redux';
import { Navbar, Nav, ListGroup } from 'react-bootstrap';
import MsgModal from '../container/MsgModal';
import { setMsgModal } from '../actions/UserAction';
import { Modal, Button, Row, Col, Card, Form, Container } from 'react-bootstrap';
import {versionInfo} from '../utils/CommonUtil';


//function Footer(props) {
  /**
 * Creates a new Footer.
 * @class
 */
const Footer = (props) => {
  /** @function
 * @name showModal */
  const showModal = (msgTitle, msgBody) => {
    props.setMsgModal({
      "title": msgTitle,
      "message": msgBody,
      "show": true
    });
  }
  /** @function
 * @name hideModal */
  const hideModal = () => {
    props.setMsgModal({
      "title": "",
      "message": "",
      "show": false
    });
  }
  /** @function
 * @name checkFooterData */
  const checkFooterData = (array,index) => {
    return (Array.isArray(array) && typeof array[index]==='object' && ("title" in array[index]) && ("htmlData" in array[index])) ? true : false 
  }

  useEffect(()=>{
    hideModal()
  }, [])

  return (
    <footer>
      { //       
        <div className="footer-wrapper">
          
          <Row className="ml-0 mr-0">
          <Col className="text-center-mob" xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }}>
          <small className="w-100-mob d-block-mob text-center-mob">
            &copy; 2020 Copyright &nbsp;<strong><a href="http://wizardcomm.net">WizardComm</a> - <a href="http://uvanij.com">uVanij.com</a></strong><span>{versionInfo()}</span>
          </small>
          
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }}>
          <nav className="p-0 w-100-mob d-block-mob text-right footer-right-section text-center-mob">
          {checkFooterData(props.footerdata,1) && <a href="#" onClick={() => showModal(props.footerdata[1].title, props.footerdata[1].htmlData)}>Contact Us</a>}
          {checkFooterData(props.footerdata,0) && <a href="#" onClick={() => showModal(props.footerdata[0].title, props.footerdata[0].htmlData)}>About Us</a> }
          {checkFooterData(props.footerdata,2) && <a href="#" onClick={() => showModal(props.footerdata[2].title, props.footerdata[2].htmlData) }>Terms & Condition</a>} 
          </nav>
          </Col>
          </Row>
          {props.modal && props.modal.show && <MsgModal show={props.modal.show}
            title={props.modal.title} message={props.modal.message} closeAction={hideModal} />}
        </div>
      }
    </footer>
  );

}

const mapStateToProps = state => ({
  modal: state.UserData.modal,
  termsandcondition: state.HomeData.termsncondnord,
  footerdata: state.HomeData.footers,
  name: state.UserData.name,
  phone: state.UserData.phone,
  email: state.UserData.email,
  query: ''
});

export default connect(mapStateToProps, { setMsgModal })(Footer);

